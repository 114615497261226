import { useEffect, useState } from 'react';
import APIEnv from '../Components/APIEnv';
import axios from 'axios';
import CommentWindow from './CommentWindow';

export default function ForumBlogPost({ id, title, content, poster, date, myblogs, index, token,numberlikes, likes, page }) {
    const [comments, setComments] = useState([]);
    const likeToken = token.slice(10)
    const deletePostShow = () => {
        const popUp = document.getElementsByClassName("popUpContainer")[0]
        popUp.innerHTML = ""
        popUp.style.opacity = "1";
        popUp.style.pointerEvents = "unset";
        popUp.append("Vill du radera inlägget?")

        const buttonDiv = document.createElement("div")
        buttonDiv.className = "d-flex"

        const yesButton = document.createElement("button")
        yesButton.innerHTML = "Radera inlägget"
        yesButton.className = "yesButton m-2"

        const noButton = document.createElement("button")
        noButton.innerHTML = "Avbryt"
        noButton.className = "noButton m-2"

        noButton.addEventListener("click", () => {
            popUp.style.opacity = "0";
            popUp.style.pointerEvents = "none";
        })
        yesButton.addEventListener("click", async () => {
            try{
                const res = await axios.post(`${APIEnv.apiUrl}/api/blogs/deleteblog`, {
                    id: id,
                    userId: token
                })
                alert("Inlägg raderat!")
                setTimeout(() => {
                    window.location.reload()
                }, 1500)
            } catch(err){
                console.log(err)
            }
        })
        buttonDiv.append(yesButton)
        buttonDiv.append(noButton)
        popUp.append(buttonDiv)

    }
    const showComments = () => {
        const commentButtons = Array.from(document.getElementsByClassName("commentCon"))
        commentButtons.forEach(element => {
            element.style.pointerEvents = "none"
        });
        setTimeout(() => {
            const commentPop = document.getElementsByClassName("commentPop")[index]
            console.log(document.getElementsByClassName("commentPop"))
            commentPop.style.opacity = 1
            commentPop.style.pointerEvents = "unset"
        }, 10)
    }
    const likepost = async () => {
        try{
            const res = await axios.get(`${APIEnv.apiUrl}/blogs/togglelike?postid=${id}&user=${likeToken}`);
            const likeheart = document.getElementsByClassName("toggleheart")[index]
            const numberholder = document.getElementsByClassName("numberLikes")[index]
            if(res.data == "unliked"){
                likeheart.className = "fa-regular fa-heart nohear toggleheart"
                numberlikes--
                numberholder.innerHTML = numberlikes
            } else {
                likeheart.className = "fa fa-heart toggleheart" 
                numberlikes++
                numberholder.innerHTML = numberlikes
            }
        } catch(e){
            console.log(e)
        }
    }
    useEffect(() => {
        const fetchComments = async () => {
            try {
                const res = await axios.get(`${APIEnv.apiUrl}/blogs/getcomments?salt=${token}&postid=${id}`);
                setComments(res.data)
            } catch (e) {
                console.log(e)
            }
        }
        fetchComments()
    }, [])
    
    return <>
        {
        page === "fasadLeft" ? <>
            <div className='fasadPost mt-3 p-4'>
                <p className=''><b>{title}</b></p>
                <p className='' id={id} dangerouslySetInnerHTML={{ __html: content }}></p>
                <i style={{fontSize: "14px"}}>{date}</i>
                {['S5WV5NuA2gbUCDJ3x9LIc8IcbyEqJNA6', '88Pz39wi5jAWwcXm8K9skFUyAXLzHX6v'].includes(token) ? <>
                    <div onClick={deletePostShow} style={{fontSize: "12px", cursor: "pointer"}} className='mt-3'>Ta bort inlägg&nbsp;&nbsp;&nbsp;<i className="fa fa-times"></i></div>
                </>:<></>}
            </div>
        </> : page === "fasadRight" ? <>
        <CommentWindow id={id} index={index} token={token} comments={comments}/>
        <div className="forumBlogpost mt-3 p-4">
            <p style={{fontWeight: "bold", color: "#99734d"}}>{title}</p>
            <p style={{fontSize: "14px"}} id={id} dangerouslySetInnerHTML={{ __html: content }}>{/* {content} */}</p>
            <i style={{fontSize: "14px"}}>{poster} {date}</i>
            <div className="commentLikeContainer mt-3">
                <div className="row">
                    <div className="col-lg-3 col-5 commentCon" style={{cursor: "pointer"}} onClick={showComments}>
                        <p>Svar&nbsp;&nbsp;&nbsp;<i className='fa fa-comment' style={{fontSize: "16px"}}></i>
                        &nbsp;&nbsp;&nbsp;<b style={{fontSize: "14px"}}>{comments.length}</b>
                        </p>
                    </div>
                    {myblogs.blogs.includes(id) || myblogs.admin ? 
                    <div className="col-lg-4 col-6 mt-lg-0" onClick={deletePostShow} style={{cursor: "pointer", fontSize: "14px"}}>
                        Radera inlägg&nbsp;&nbsp;&nbsp;&nbsp;
                        <i className="fa fa-times"></i>
                    </div> : <></>}
                </div>
            </div>
        </div>

        </> :
        <>
        <CommentWindow id={id} index={index} token={token} comments={comments}/>
        <div className="forumBlogpost mt-3 p-4">
            <p style={{fontWeight: "bold", color: "#99734d"}}>{title}</p>
            <p style={{fontSize: "14px"}} id={id} dangerouslySetInnerHTML={{ __html: content }}>{/* {content} */}</p>
            <i style={{fontSize: "14px"}}>{poster} {date}</i>
            <div className="commentLikeContainer mt-3">
                <div className="row">
                    <div className="col-lg-1 col-3 commentCon" onClick={showComments}>
                        <i className="fa fa-comment" style={{fontSize: "20px"}}></i>
                        &nbsp;&nbsp;&nbsp;<b style={{fontSize: "14px"}}>{comments.length}</b>
                    </div>
                    <div className="col-lg-1 col-5">

                        {likes.includes(likeToken) ? <>
                            <i className="fa fa-heart toggleheart" onClick={likepost} style={{fontSize: "20px"}}></i>
                        </> : <>
                        
                        <i  className="fa-regular fa-heart nohear toggleheart" onClick={likepost} style={{fontSize: "20px"}}></i>
                        </>}
                        &nbsp;&nbsp;&nbsp;<b id="numberLikes" className='numberLikes' style={{fontSize: "14px"}}>{numberlikes}</b>

                    </div>
                    {myblogs.blogs.includes(id) || myblogs.admin ? 
                    <div className="col-lg-2 col-6 mt-3 mt-lg-0" onClick={deletePostShow} style={{cursor: "pointer", fontSize: "14px"}}>
                        Radera inlägg&nbsp;&nbsp;&nbsp;&nbsp;
                        <i className="fa fa-times"></i>
                    </div> : <></>}
                </div>
            </div>
        </div>
        </>
        }
    </>
}